/* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

/* Gochi Hand */
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");

/* Rebrand Txt */

/*
Font: 		Rebrand Txt
Style: 		Light it
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: "RebrandTxt-Lightit";
  src: url("https://pairtreefamily.com/fonts/rebrand-text-light-it.woff2")
    format("woff2");
  font-weight: 300;
  font-style: italic;
}
/*
Font: 		Rebrand Txt
Style: 		Regular
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-text-regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}

/*
Font: 		Rebrand Txt
Style: 		Bold
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-text-bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

/* Rebrand Display */

/*
Font: 		Rebrand Dis
Style: 		Regular it
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-display-regular-it.woff2")
    format("woff2");
  font-weight: 400;
  font-style: italic;
}

/*
Font: 		Rebrand Dis
Style: 		Bold it
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-display-bold-it.woff2")
    format("woff2");
  font-weight: 700;
  font-style: italic;
}
