@import "./fonts.css";

/*Tailwind base styles overrides button background color, so this css-reset file same as tailwind base, but without button styles*/
@import "css-reset.css";

@import "tailwindcss/components";
@import "components.css";

@import "tailwindcss/utilities";
@import "utilities.css";
