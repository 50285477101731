@layer utilities {
  *:not(svg*) {
    color: #4d4d4d;
  }
  * strong,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #333333;
  }
  a {
    @apply text-body-4;
    @apply text-teal;
  }
  a:hover {
    text-decoration: underline;
  }

  #__next {
    @apply h-full;
  }
  html,
  body {
    @apply h-full;
  }
}
