
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");
/* Montserrat */
/* Gochi Hand */
/* Rebrand Txt */
/*
Font: 		Rebrand Txt
Style: 		Light it
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/
@font-face {
  font-family: "RebrandTxt-Lightit";
  src: url("https://pairtreefamily.com/fonts/rebrand-text-light-it.woff2")
    format("woff2");
  font-weight: 300;
  font-style: italic;
}
/*
Font: 		Rebrand Txt
Style: 		Regular
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/
@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-text-regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}
/*
Font: 		Rebrand Txt
Style: 		Bold
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/
@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-text-bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}
/* Rebrand Display */
/*
Font: 		Rebrand Dis
Style: 		Regular it
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/
@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-display-regular-it.woff2")
    format("woff2");
  font-weight: 400;
  font-style: italic;
}
/*
Font: 		Rebrand Dis
Style: 		Bold it
URL: 		https://youworkforthem.com/font/T15270/rebrand
Foundry: 	LatinoType
Foundry: 	https://youworkforthem.com/designer/220/latinotype
Copyright:	Copyright © 2021 by Latinotype. All rights reserved.
Version: 	17
Created:	December 27, 2021
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/
@font-face {
  font-family: "Rebrand";
  src: url("https://pairtreefamily.com/fonts/rebrand-display-bold-it.woff2")
    format("woff2");
  font-weight: 700;
  font-style: italic;
}
/*Tailwind base styles overrides button background color, so this css-reset file same as tailwind base, but without button styles*/
/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: currentColor; /* 2 */
}
::before,
::after {
  --tw-content: "";
}
/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/
/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/
body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}
/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/
hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}
/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
/*
Remove the default font size and weight for headings.
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
/*
Reset links to optimize for opt-in styling instead of opt-out.
*/
a {
  color: inherit;
  text-decoration: inherit;
}
/*
Add the correct font weight in Edge and Safari.
*/
b,
strong {
  font-weight: bolder;
}
/*
Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}
/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}
/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-feature-settings: inherit; /* 1 */
  font-variation-settings: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  background-color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}
/*
Remove the inheritance of text transform in Edge and Firefox.
*/
button,
select {
  text-transform: none;
}
/*
Use the modern Firefox focus style for all focusable elements.
*/
:-moz-focusring {
  outline: auto;
}
/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/
:-moz-ui-invalid {
  box-shadow: none;
}
/*
Add the correct vertical alignment in Chrome and Firefox.
*/
progress {
  vertical-align: baseline;
}
/*
Correct the cursor style of increment and decrement buttons in Safari.
*/
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/*
Remove the inner padding in Chrome and Safari on macOS.
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/*
Add the correct display in Chrome and Safari.
*/
summary {
  display: list-item;
}
/*
Removes the default spacing and border for appropriate elements.
*/
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*
Reset default styling for dialogs.
*/
dialog {
  padding: 0;
}
/*
Prevent resizing textareas horizontally by default.
*/
textarea {
  resize: vertical;
}
/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/
input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}
/*
Set the default cursor for buttons.
*/
button,
[role="button"] {
  cursor: pointer;
}
/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}
/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}
/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/
img,
video {
  max-width: 100%;
  height: auto;
}
/* Make elements with the HTML hidden attribute stay hidden by default */
[hidden] {
  display: none;
}
.container{
  width: 100%;
}
@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}
@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}
@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}
@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}
@media (min-width: 1536px){
  .container{
    max-width: 1536px;
  }
}
/* heading type */
.text-head-1{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 65px;
  line-height: 75px;
    font-weight: 700;
}
.text-head-1 > em{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 75px;
  line-height: 80px;
    font-weight: 700;
}
.text-head-2{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 46px;
  line-height: 56px;
    font-weight: 700;
}
.text-head-2 > em{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 55px;
  line-height: 60px;
    font-style: italic;
    font-weight: 700;
}
.text-head-3{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 38px;
  line-height: 48px;
    font-weight: 700;
}
.text-head-3 > em{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 45px;
  line-height: 50px;
    font-style: italic;
    font-weight: 700;
}
.text-head-4{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
}
.text-head-4 > em{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: italic;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
}
.text-head-5{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  line-height: 35px;
    font-weight: 700;
}
.text-head-6{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  line-height: 34px;
    font-weight: 700;
}
.text-head-7{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  line-height: 30px;
    font-weight: 700;
    color: black;
    /* no h7 html element */
}
/* TODO: utility class? reorder headings? */
.text-head-8{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 40px;
  line-height: 46px;
    font-weight: 700;
    font-style: italic;
}
/* body type */
.text-body-1{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  line-height: 30px;
    font-weight: 400;
}
.text-body-2{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 17px;
  line-height: 27px;
    font-weight: 400;
}
.text-body-3{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  line-height: 25px;
    font-weight: 400;
}
.text-body-3-medium{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  line-height: 25px;
    font-weight: 500;
}
.text-body-3-semi{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  line-height: 25px;
    font-weight: 600;
}
.text-body-4{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  line-height: 20px;
    font-weight: 400;
}
.text-body-4-semi{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  line-height: 20px;
    font-weight: 600;
}
.text-body-5{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  line-height: 20px;
    font-weight: 400;
}
/* utility type */
.text-overline-1{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  line-height: 25px;
    text-transform: uppercase;
    font-weight: 700;
}
.text-overline-2{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
}
.text-overline-3{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 17px;
  line-height: 27px;
    text-transform: uppercase;
    font-weight: 700;
}
.text-handwriting-1{
  font-family: Gochi Hand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 60px;
  line-height: 70px;
}
.text-handwriting-2{
  font-family: Gochi Hand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  line-height: 35px;
}
.text-handwriting-3{
  font-family: Gochi Hand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  line-height: 29px;
}
.text-quotation-1{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 45px;
  line-height: 50px;
    font-weight: 400;
    font-style: italic;
}
.text-quotation-2{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  line-height: 45px;
    font-weight: 400;
    font-style: italic;
}
.text-quotation-3{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 40px;
  line-height: 46px;
    font-weight: 400;
    font-style: italic;
}
.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none{
  pointer-events: none;
}
.visible{
  visibility: visible;
}
.\!visible{
  visibility: visible !important;
}
.invisible{
  visibility: hidden;
}
.static{
  position: static;
}
.fixed{
  position: fixed;
}
.absolute{
  position: absolute;
}
.relative{
  position: relative;
}
.sticky{
  position: sticky;
}
.inset-0{
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.inset-y-0{
  top: 0px;
  bottom: 0px;
}
.top-0{
  top: 0px;
}
.right-0{
  right: 0px;
}
.z-10{
  z-index: 10;
}
.col-span-2{
  grid-column: span 2 / span 2;
}
.m-auto{
  margin: auto;
}
.my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-8{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mb-7{
  margin-bottom: 1.75rem;
}
.mr-2\.5{
  margin-right: 0.625rem;
}
.mr-2{
  margin-right: 0.5rem;
}
.ml-2{
  margin-left: 0.5rem;
}
.mb-9{
  margin-bottom: 2.25rem;
}
.mb-4{
  margin-bottom: 1rem;
}
.mt-12{
  margin-top: 3rem;
}
.mb-11{
  margin-bottom: 2.75rem;
}
.mb-2\.5{
  margin-bottom: 0.625rem;
}
.mb-2{
  margin-bottom: 0.5rem;
}
.mt-16{
  margin-top: 4rem;
}
.mt-1{
  margin-top: 0.25rem;
}
.mt-4{
  margin-top: 1rem;
}
.mt-6{
  margin-top: 1.5rem;
}
.-mb-2{
  margin-bottom: -0.5rem;
}
.mt-3{
  margin-top: 0.75rem;
}
.mt-2{
  margin-top: 0.5rem;
}
.mb-\[5px\]{
  margin-bottom: 5px;
}
.ml-3{
  margin-left: 0.75rem;
}
.mt-5{
  margin-top: 1.25rem;
}
.mb-8{
  margin-bottom: 2rem;
}
.mt-8{
  margin-top: 2rem;
}
.mr-4{
  margin-right: 1rem;
}
.ml-4{
  margin-left: 1rem;
}
.-ml-\[60px\]{
  margin-left: -60px;
}
.-mt-4{
  margin-top: -1rem;
}
.mt-\[3px\]{
  margin-top: 3px;
}
.block{
  display: block;
}
.inline-block{
  display: inline-block;
}
.flex{
  display: flex;
}
.table{
  display: table;
}
.\!table{
  display: table !important;
}
.flow-root{
  display: flow-root;
}
.grid{
  display: grid;
}
.contents{
  display: contents;
}
.hidden{
  display: none;
}
.h-6{
  height: 1.5rem;
}
.h-full{
  height: 100%;
}
.h-\[50px\]{
  height: 50px;
}
.h-\[25px\]{
  height: 25px;
}
.h-screen{
  height: 100vh;
}
.h-\[40px\]{
  height: 40px;
}
.h-\[2px\]{
  height: 2px;
}
.h-\[18px\]{
  height: 18px;
}
.h-\[5px\]{
  height: 5px;
}
.h-\[100px\]{
  height: 100px;
}
.max-h-60{
  max-height: 15rem;
}
.max-h-0{
  max-height: 0px;
}
.max-h-screen{
  max-height: 100vh;
}
.min-h-full{
  min-height: 100%;
}
.w-6{
  width: 1.5rem;
}
.w-\[236px\]{
  width: 236px;
}
.w-\[175px\]{
  width: 175px;
}
.w-full{
  width: 100%;
}
.w-\[300px\]{
  width: 300px;
}
.w-\[500px\]{
  width: 500px;
}
.w-\[1px\]{
  width: 1px;
}
.w-\[98px\]{
  width: 98px;
}
.w-\[60px\]{
  width: 60px;
}
.w-\[18px\]{
  width: 18px;
}
.w-48{
  width: 12rem;
}
.w-\[34px\]{
  width: 34px;
}
.w-auto{
  width: auto;
}
.w-\[100px\]{
  width: 100px;
}
.min-w-fit{
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.min-w-0{
  min-width: 0px;
}
.max-w-\[790px\]{
  max-width: 790px;
}
.max-w-\[360px\]{
  max-width: 360px;
}
.max-w-\[600px\]{
  max-width: 600px;
}
.max-w-screen-2xl{
  max-width: 1536px;
}
.max-w-\[850px\]{
  max-width: 850px;
}
.max-w-\[900px\]{
  max-width: 900px;
}
.max-w-\[120px\]{
  max-width: 120px;
}
.max-w-\[550px\]{
  max-width: 550px;
}
.max-w-\[200px\]{
  max-width: 200px;
}
.max-w-full{
  max-width: 100%;
}
.flex-1{
  flex: 1 1 0%;
}
.flex-shrink-0{
  flex-shrink: 0;
}
.shrink{
  flex-shrink: 1;
}
.grow{
  flex-grow: 1;
}
.grow-0{
  flex-grow: 0;
}
.basis-1\/4{
  flex-basis: 25%;
}
.basis-1\/2{
  flex-basis: 50%;
}
.translate-y-4{
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0{
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180{
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes spin{
  to{
    transform: rotate(360deg);
  }
}
.animate-spin{
  animation: spin 1s linear infinite;
}
@keyframes pulse{
  50%{
    opacity: .5;
  }
}
.animate-pulse{
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-default{
  cursor: default;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-grab{
  cursor: grab;
}
.select-none{
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.list-disc{
  list-style-type: disc;
}
.list-none{
  list-style-type: none;
}
.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.flex-row{
  flex-direction: row;
}
.flex-col{
  flex-direction: column;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-nowrap{
  flex-wrap: nowrap;
}
.content-center{
  align-content: center;
}
.items-start{
  align-items: flex-start;
}
.items-end{
  align-items: flex-end;
}
.items-center{
  align-items: center;
}
.justify-start{
  justify-content: flex-start;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.gap-6{
  gap: 1.5rem;
}
.gap-2{
  gap: 0.5rem;
}
.gap-8{
  gap: 2rem;
}
.gap-4{
  gap: 1rem;
}
.gap-5{
  gap: 1.25rem;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-backgroundgray > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgb(248 248 248 / var(--tw-divide-opacity));
}
.overflow-auto{
  overflow: auto;
}
.overflow-hidden{
  overflow: hidden;
}
.overflow-scroll{
  overflow: scroll;
}
.overflow-y-auto{
  overflow-y: auto;
}
.truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.rounded-\[10px\]{
  border-radius: 10px;
}
.rounded-full{
  border-radius: 9999px;
}
.rounded-\[25px\]{
  border-radius: 25px;
}
.rounded-md{
  border-radius: 0.375rem;
}
.rounded-lg{
  border-radius: 0.5rem;
}
.rounded-\[15px\]{
  border-radius: 15px;
}
.rounded-\[12px\]{
  border-radius: 12px;
}
.border{
  border-width: 1px;
}
.border-\[2px\]{
  border-width: 2px;
}
.border-2{
  border-width: 2px;
}
.border-\[1px\]{
  border-width: 1px;
}
.border-b-2{
  border-bottom-width: 2px;
}
.border-b-\[1px\]{
  border-bottom-width: 1px;
}
.border-\[\#F3F4F5\]{
  --tw-border-opacity: 1;
  border-color: rgb(243 244 245 / var(--tw-border-opacity));
}
.border-backgroundgray{
  --tw-border-opacity: 1;
  border-color: rgb(248 248 248 / var(--tw-border-opacity));
}
.border-darkblue{
  --tw-border-opacity: 1;
  border-color: rgb(0 102 121 / var(--tw-border-opacity));
}
.border-teal{
  --tw-border-opacity: 1;
  border-color: rgb(68 151 141 / var(--tw-border-opacity));
}
.border-green{
  --tw-border-opacity: 1;
  border-color: rgb(153 202 68 / var(--tw-border-opacity));
}
.border-backgroundgray-shade{
  --tw-border-opacity: 1;
  border-color: rgb(223 223 223 / var(--tw-border-opacity));
}
.border-\[\#f43f5d\]{
  --tw-border-opacity: 1;
  border-color: rgb(244 63 93 / var(--tw-border-opacity));
}
.border-gray{
  --tw-border-opacity: 1;
  border-color: rgb(106 106 106 / var(--tw-border-opacity));
}
.border-purple{
  --tw-border-opacity: 1;
  border-color: rgb(141 77 119 / var(--tw-border-opacity));
}
.border-peach{
  --tw-border-opacity: 1;
  border-color: rgb(250 142 115 / var(--tw-border-opacity));
}
.border-rust{
  --tw-border-opacity: 1;
  border-color: rgb(222 74 57 / var(--tw-border-opacity));
}
.border-platinum{
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
}
.border-cultured{
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}
.border-lightgray{
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}
.bg-darkblue{
  --tw-bg-opacity: 1;
  background-color: rgb(0 102 121 / var(--tw-bg-opacity));
}
.bg-teal{
  --tw-bg-opacity: 1;
  background-color: rgb(68 151 141 / var(--tw-bg-opacity));
}
.bg-green{
  --tw-bg-opacity: 1;
  background-color: rgb(153 202 68 / var(--tw-bg-opacity));
}
.bg-backgroundgray{
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}
.bg-backgroundgray-shade{
  --tw-bg-opacity: 1;
  background-color: rgb(223 223 223 / var(--tw-bg-opacity));
}
.bg-lightgray{
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}
.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-purple{
  --tw-bg-opacity: 1;
  background-color: rgb(141 77 119 / var(--tw-bg-opacity));
}
.bg-peach{
  --tw-bg-opacity: 1;
  background-color: rgb(250 142 115 / var(--tw-bg-opacity));
}
.bg-rust{
  --tw-bg-opacity: 1;
  background-color: rgb(222 74 57 / var(--tw-bg-opacity));
}
.bg-darkblue-shade{
  --tw-bg-opacity: 1;
  background-color: rgb(0 92 109 / var(--tw-bg-opacity));
}
.bg-platinum{
  --tw-bg-opacity: 1;
  background-color: rgb(235 235 235 / var(--tw-bg-opacity));
}
.bg-background-gray{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 245 / var(--tw-bg-opacity));
}
.bg-opacity-75{
  --tw-bg-opacity: 0.75;
}
.bg-none{
  background-image: none;
}
.fill-green{
  fill: #99CA44;
}
.fill-mango{
  fill: #ffc123;
}
.fill-rust{
  fill: #DE4A39;
}
.fill-lightgray{
  fill: #cccccc;
}
.stroke-green{
  stroke: #99CA44;
}
.stroke-mango{
  stroke: #ffc123;
}
.stroke-rust{
  stroke: #DE4A39;
}
.stroke-lightgray{
  stroke: #cccccc;
}
.p-2{
  padding: 0.5rem;
}
.p-4{
  padding: 1rem;
}
.p-16{
  padding: 4rem;
}
.p-6{
  padding: 1.5rem;
}
.p-8{
  padding: 2rem;
}
.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-1\.5{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-\[16px\]{
  padding-top: 16px;
  padding-bottom: 16px;
}
.px-\[20px\]{
  padding-left: 20px;
  padding-right: 20px;
}
.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-8{
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-16{
  padding-left: 4rem;
  padding-right: 4rem;
}
.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-1\.5{
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.pb-\[10px\]{
  padding-bottom: 10px;
}
.pl-3{
  padding-left: 0.75rem;
}
.pr-9{
  padding-right: 2.25rem;
}
.pr-10{
  padding-right: 2.5rem;
}
.pt-2{
  padding-top: 0.5rem;
}
.pt-5{
  padding-top: 1.25rem;
}
.pb-4{
  padding-bottom: 1rem;
}
.pr-5{
  padding-right: 1.25rem;
}
.pr-2{
  padding-right: 0.5rem;
}
.pl-4{
  padding-left: 1rem;
}
.pr-1{
  padding-right: 0.25rem;
}
.pb-1{
  padding-bottom: 0.25rem;
}
.pt-7{
  padding-top: 1.75rem;
}
.pb-8{
  padding-bottom: 2rem;
}
.pb-2{
  padding-bottom: 0.5rem;
}
.pt-8{
  padding-top: 2rem;
}
.pt-4{
  padding-top: 1rem;
}
.pt-6{
  padding-top: 1.5rem;
}
.pb-12{
  padding-bottom: 3rem;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.font-rebrand{
  font-family: Rebrand, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.text-\[35px\]{
  font-size: 35px;
}
.font-bold{
  font-weight: 700;
}
.font-semibold{
  font-weight: 600;
}
.font-normal{
  font-weight: 400;
}
.font-extrabold{
  font-weight: 800;
}
.uppercase{
  text-transform: uppercase;
}
.italic{
  font-style: italic;
}
.leading-none{
  line-height: 1;
}
.text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-\[\#414141\]{
  --tw-text-opacity: 1;
  color: rgb(65 65 65 / var(--tw-text-opacity));
}
.\!text-white{
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-teal{
  --tw-text-opacity: 1;
  color: rgb(68 151 141 / var(--tw-text-opacity));
}
.text-darkblue{
  --tw-text-opacity: 1;
  color: rgb(0 102 121 / var(--tw-text-opacity));
}
.text-backgroundgray{
  --tw-text-opacity: 1;
  color: rgb(248 248 248 / var(--tw-text-opacity));
}
.text-green{
  --tw-text-opacity: 1;
  color: rgb(153 202 68 / var(--tw-text-opacity));
}
.text-backgroundgray-shade{
  --tw-text-opacity: 1;
  color: rgb(223 223 223 / var(--tw-text-opacity));
}
.\!text-black{
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.text-\[\#f43f5d\]{
  --tw-text-opacity: 1;
  color: rgb(244 63 93 / var(--tw-text-opacity));
}
.text-gray{
  --tw-text-opacity: 1;
  color: rgb(106 106 106 / var(--tw-text-opacity));
}
.text-purple{
  --tw-text-opacity: 1;
  color: rgb(141 77 119 / var(--tw-text-opacity));
}
.text-peach{
  --tw-text-opacity: 1;
  color: rgb(250 142 115 / var(--tw-text-opacity));
}
.text-rust{
  --tw-text-opacity: 1;
  color: rgb(222 74 57 / var(--tw-text-opacity));
}
.text-darkgray{
  --tw-text-opacity: 1;
  color: rgb(65 65 65 / var(--tw-text-opacity));
}
.text-onyx{
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}
.text-\[\#A0A0A0\]{
  --tw-text-opacity: 1;
  color: rgb(160 160 160 / var(--tw-text-opacity));
}
.text-red-600{
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-gray-tint{
  --tw-text-opacity: 1;
  color: rgb(121 121 121 / var(--tw-text-opacity));
}
.text-lightgray{
  --tw-text-opacity: 1;
  color: rgb(204 204 204 / var(--tw-text-opacity));
}
.text-onyx-light{
  --tw-text-opacity: 1;
  color: rgb(94 94 94 / var(--tw-text-opacity));
}
.text-mango{
  --tw-text-opacity: 1;
  color: rgb(255 193 35 / var(--tw-text-opacity));
}
.underline{
  text-decoration-line: underline;
}
.overline{
  text-decoration-line: overline;
}
.opacity-30{
  opacity: 0.3;
}
.opacity-0{
  opacity: 0;
}
.opacity-100{
  opacity: 1;
}
.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_4px_15px_0_rgba\(0\2c 0\2c 0\2c 0\.03\)\]{
  --tw-shadow: 0 4px 15px 0 rgba(0,0,0,0.03);
  --tw-shadow-colored: 0 4px 15px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline{
  outline-style: solid;
}
.ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-black{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.ring-opacity-5{
  --tw-ring-opacity: 0.05;
}
.filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition-colors{
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity{
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition{
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-500{
  transition-duration: 500ms;
}
.duration-200{
  transition-duration: 200ms;
}
.duration-75{
  transition-duration: 75ms;
}
.duration-300{
  transition-duration: 300ms;
}
.duration-100{
  transition-duration: 100ms;
}
.ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
*:not(svg*) {
    color: #4d4d4d;
  }
* strong,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #333333;
  }
a{
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  line-height: 20px;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(68 151 141 / var(--tw-text-opacity));
}
a:hover {
    text-decoration: underline;
  }
#__next{
  height: 100%;
}
html,
  body{
  height: 100%;
}
.placeholder\:italic::-moz-placeholder{
  font-style: italic;
}
.placeholder\:italic::placeholder{
  font-style: italic;
}
.placeholder\:text-quicksilver::-moz-placeholder{
  --tw-text-opacity: 1;
  color: rgb(160 160 160 / var(--tw-text-opacity));
}
.placeholder\:text-quicksilver::placeholder{
  --tw-text-opacity: 1;
  color: rgb(160 160 160 / var(--tw-text-opacity));
}
.even\:bg-backgroundgray:nth-child(even){
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}
.hover\:scale-110:hover{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:border-teal-tint:hover{
  --tw-border-opacity: 1;
  border-color: rgb(76 169 158 / var(--tw-border-opacity));
}
.hover\:text-darkgray:hover{
  --tw-text-opacity: 1;
  color: rgb(65 65 65 / var(--tw-text-opacity));
}
.hover\:text-teal:hover{
  --tw-text-opacity: 1;
  color: rgb(68 151 141 / var(--tw-text-opacity));
}
.focus\:border-teal:focus{
  --tw-border-opacity: 1;
  border-color: rgb(68 151 141 / var(--tw-border-opacity));
}
.focus\:outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:ring-teal-tint:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(76 169 158 / var(--tw-ring-opacity));
}
.enabled\:hover\:border-teal-tint:hover:enabled{
  --tw-border-opacity: 1;
  border-color: rgb(76 169 158 / var(--tw-border-opacity));
}
.enabled\:hover\:border-green-tint:hover:enabled{
  --tw-border-opacity: 1;
  border-color: rgb(164 208 88 / var(--tw-border-opacity));
}
.enabled\:hover\:border-peach-tint:hover:enabled{
  --tw-border-opacity: 1;
  border-color: rgb(251 153 129 / var(--tw-border-opacity));
}
.enabled\:hover\:border-rust-tint:hover:enabled{
  --tw-border-opacity: 1;
  border-color: rgb(226 95 80 / var(--tw-border-opacity));
}
.enabled\:hover\:bg-teal-tint:hover:enabled{
  --tw-bg-opacity: 1;
  background-color: rgb(76 169 158 / var(--tw-bg-opacity));
}
.enabled\:hover\:bg-green-tint:hover:enabled{
  --tw-bg-opacity: 1;
  background-color: rgb(164 208 88 / var(--tw-bg-opacity));
}
.enabled\:hover\:bg-peach-tint:hover:enabled{
  --tw-bg-opacity: 1;
  background-color: rgb(251 153 129 / var(--tw-bg-opacity));
}
.enabled\:hover\:bg-rust-tint:hover:enabled{
  --tw-bg-opacity: 1;
  background-color: rgb(226 95 80 / var(--tw-bg-opacity));
}
.enabled\:hover\:text-white:hover:enabled{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.enabled\:hover\:text-teal-tint:hover:enabled{
  --tw-text-opacity: 1;
  color: rgb(76 169 158 / var(--tw-text-opacity));
}
.enabled\:hover\:text-green-tint:hover:enabled{
  --tw-text-opacity: 1;
  color: rgb(164 208 88 / var(--tw-text-opacity));
}
.enabled\:hover\:text-peach-tint:hover:enabled{
  --tw-text-opacity: 1;
  color: rgb(251 153 129 / var(--tw-text-opacity));
}
.enabled\:hover\:text-rust-tint:hover:enabled{
  --tw-text-opacity: 1;
  color: rgb(226 95 80 / var(--tw-text-opacity));
}
.enabled\:hover\:transition-all:hover:enabled{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.active\:enabled\:border-teal:enabled:active{
  --tw-border-opacity: 1;
  border-color: rgb(68 151 141 / var(--tw-border-opacity));
}
.active\:enabled\:border-green:enabled:active{
  --tw-border-opacity: 1;
  border-color: rgb(153 202 68 / var(--tw-border-opacity));
}
.active\:enabled\:border-purple:enabled:active{
  --tw-border-opacity: 1;
  border-color: rgb(141 77 119 / var(--tw-border-opacity));
}
.active\:enabled\:border-peach:enabled:active{
  --tw-border-opacity: 1;
  border-color: rgb(250 142 115 / var(--tw-border-opacity));
}
.active\:enabled\:border-rust:enabled:active{
  --tw-border-opacity: 1;
  border-color: rgb(222 74 57 / var(--tw-border-opacity));
}
.active\:enabled\:bg-teal:enabled:active{
  --tw-bg-opacity: 1;
  background-color: rgb(68 151 141 / var(--tw-bg-opacity));
}
.active\:enabled\:bg-green:enabled:active{
  --tw-bg-opacity: 1;
  background-color: rgb(153 202 68 / var(--tw-bg-opacity));
}
.active\:enabled\:bg-purple:enabled:active{
  --tw-bg-opacity: 1;
  background-color: rgb(141 77 119 / var(--tw-bg-opacity));
}
.active\:enabled\:bg-peach:enabled:active{
  --tw-bg-opacity: 1;
  background-color: rgb(250 142 115 / var(--tw-bg-opacity));
}
.active\:enabled\:bg-rust:enabled:active{
  --tw-bg-opacity: 1;
  background-color: rgb(222 74 57 / var(--tw-bg-opacity));
}
.active\:enabled\:text-teal:enabled:active{
  --tw-text-opacity: 1;
  color: rgb(68 151 141 / var(--tw-text-opacity));
}
.active\:enabled\:text-green:enabled:active{
  --tw-text-opacity: 1;
  color: rgb(153 202 68 / var(--tw-text-opacity));
}
.active\:enabled\:text-purple:enabled:active{
  --tw-text-opacity: 1;
  color: rgb(141 77 119 / var(--tw-text-opacity));
}
.active\:enabled\:text-peach:enabled:active{
  --tw-text-opacity: 1;
  color: rgb(250 142 115 / var(--tw-text-opacity));
}
.active\:enabled\:text-rust:enabled:active{
  --tw-text-opacity: 1;
  color: rgb(222 74 57 / var(--tw-text-opacity));
}
.disabled\:cursor-default:disabled{
  cursor: default;
}
.disabled\:border-lightgray:disabled{
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}
.disabled\:bg-lightgray:disabled{
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}
.disabled\:text-white:disabled{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.disabled\:text-lightgray:disabled{
  --tw-text-opacity: 1;
  color: rgb(204 204 204 / var(--tw-text-opacity));
}
.disabled\:opacity-50:disabled{
  opacity: 0.5;
}
.group\/child:hover .group-hover\/child\:block{
  display: block;
}
.group\/parent:hover .group-hover\/parent\:block{
  display: block;
}
.group:hover .group-hover\:text-teal{
  --tw-text-opacity: 1;
  color: rgb(68 151 141 / var(--tw-text-opacity));
}
@media (min-width: 640px){
  .sm\:my-8{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:mt-5{
    margin-top: 1.25rem;
  }
  .sm\:block{
    display: block;
  }
  .sm\:translate-y-0{
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:items-center{
    align-items: center;
  }
  .sm\:p-0{
    padding: 0px;
  }
  .sm\:p-6{
    padding: 1.5rem;
  }
}
.\[\&\>button\]\:pointer-events-none>button{
  pointer-events: none;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

